import React from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";

const Downloads = () => {
  return (
    <Layout>
      <DashboardLayout>Downloads</DashboardLayout>
    </Layout>
  );
};

export default Downloads;
